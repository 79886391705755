.reservation_page {
    height: 100vh;
    width: 100vw !important;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reservation_page .text_part .reservation_logo {
    padding: 45px 0px 45px 68px !important;
}

.reservation_page .text_part .reservation_logo div img {
    height: 28px;
    width: 179px;
}

.reservation_page .text_part .text_box {
    padding: 40px 83px 0px 82px;
}

@media screen and (min-width:3000px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px black;
        line-height: 44px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 89%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 37px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 161px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        min-height: 650px !important;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        min-height: 650px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 80% !important;
        height: 95% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 350px !important;
        height: 450px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 30px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 48.59px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:2300px) and (max-width:2999px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px black;
        line-height: 44px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 89%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 37px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 161px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 600px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 550px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 80% !important;
        height: 95% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 350px !important;
        height: 450px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 30px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 48.59px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:2000px) and (max-width:2299px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px black;
        line-height: 44px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 89%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 37px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 161px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 404px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 404px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 80% !important;
        height: 95% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 350px !important;
        height: 450px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 30px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 48.59px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1800px) and (max-width:1999px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 65%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px black;
        line-height: 44px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 89%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 37px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 161px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 46.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 404px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 404px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 350px !important;
        height: 400px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 38px !important;
    }

    /* .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    } */

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 48.59px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1430px) and (max-width:1799px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 48px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 82%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 28px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 55px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 404px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 404px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 90% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 45px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 281px !important;
        height: 366px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 68px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width: 1430px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 48px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 82%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 28px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 55px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 404px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 404px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 90% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 45px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 275px !important;
        height: 355px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 68px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 880px) and (max-height: 949px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 48px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 82%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 28px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 55px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 404px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 404px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 90% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 45px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 272px !important;
        height: 350px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 68px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 800px) and (max-height: 879px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 48px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 82%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 28px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 55px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 380px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 15px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 300px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 50px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 750px) and (max-height: 799px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 42px;
        padding-top: 16px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 82%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 42px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 350px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 15px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 300px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 50px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 700px) and (max-height: 749px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 42px;
        padding-top: 16px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 82%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 42px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 46vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 15px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 300px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 50px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 630px) and (max-height: 699px) {

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 20px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 80%;
        max-width: 700px;
        font-size: 28px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 77%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 44vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 84% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 15px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 265px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 32px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 22px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 550px) and (max-height: 629px) {

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 20px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 80%;
        max-width: 700px;
        font-size: 28px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 77%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 47vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        min-height: 280px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 76% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 15px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 230px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 32px 32px 32px 32px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 22px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 6px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (max-height: 549px) {

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 20px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 80%;
        max-width: 700px;
        font-size: 28px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px black;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 77%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 400 !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 138.1px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        min-height: 20px !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 70% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 15px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 210px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box img {
        width: 281px;
        height: 366px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 24px 32px 24px 32px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 26px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 6px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 370px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 90% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 320px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 60px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 370px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 91% !important;
        height: 90% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 250px !important;
        height: 310px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 60px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 370px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 88% !important;
        height: 89% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 250px !important;
        height: 310px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 55px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 43vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 370px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 84% !important;
        height: 86% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 245px !important;
        height: 285px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 57vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 55px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 750px) and (max-height: 799px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 370px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 86% !important;
        height: 92% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 235px !important;
        height: 270px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 55px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 700px) and (max-height: 749px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 350px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 86% !important;
        height: 92% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 235px !important;
        height: 270px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 55px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 22px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 630px) and (max-height: 699px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 43vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 300px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 87% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 35px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 230px !important;
        height: 260px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 59vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 55px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 22px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 27px;
        letter-spacing: 0.2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 550px) and (max-height: 629px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 45px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 49vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        min-height: 280px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 87% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 35px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 230px !important;
        height: 260px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 50vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 32px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 50% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 6px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (max-height: 549px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 36px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 49vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        min-height: 280px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 82% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 215px !important;
        height: 230px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 52vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 32px 32px 32px 32px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 50% !important;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 6px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 345px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 250px !important;
        height: 290px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 42px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 13px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 950px) and (max-height: 1009px) {

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 345px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 250px !important;
        height: 290px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 36px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 13px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 345px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 240px !important;
        height: 280px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 36px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 13px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 43vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 345px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 230px !important;
        height: 270px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 36px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 13px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 345px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 230px !important;
        height: 270px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 55vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 13px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 47vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 325px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 220px !important;
        height: 250px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 53vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 13px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        min-height: 290px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 96% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 220px !important;
        height: 250px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 55vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 10px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 44vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        min-height: 255px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 80% !important;
        height: 96% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 210px !important;
        height: 230px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 55vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 30px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 10px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 25px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;

    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        min-height: 255px;
        padding: 0px 32px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 80% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 210px !important;
        height: 210px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -5px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 57vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 32px 32px 32px 32px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 24px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 10px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 26px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 60% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 200px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -80px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        /* width: 100%; */
        height: 55vh;
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        justify-content: flex-start;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 22px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 11px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 26px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 60% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 200px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -80px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 58vh;
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 18px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 22px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 11px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 26px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 67% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 200px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -80px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 40px 18px 18px 18px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 26px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 92% !important;
        height: 71% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 25px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 200px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -80px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 220px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 59vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        justify-content: flex-start;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }


    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 15px;
        line-height: 24px;
        margin-top: 26px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 87% !important;
        height: 77% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 20px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 200px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -80px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 220px;
        height: auto;
        margin-top: 7px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 36px 18px 18px 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 22px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 15px;
        line-height: 24px;
        margin-top: 26px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }


    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 89% !important;
        height: 86% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 210px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -75px;
        background-size: contain !important;
        margin-top: 10px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 10px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 58vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 52px 18px 18px 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 6px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 12px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 22px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 15px;
        line-height: 24px;
        margin-top: 26px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 18px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 87% !important;
        height: 89% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 215px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -75px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 7px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9.5px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 17px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9.5px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 17px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 56vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 60px 18px 18px 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 16px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 4px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 22px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 15px;
        line-height: 24px;
        margin-top: 26px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 82% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 78% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }


    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 20px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 17px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 17px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 51vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 30px 15px 15px 15px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 32px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 4px;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 8px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 32px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 22px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 16px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 15px;
        line-height: 24px;
        margin-top: 26px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 82% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 78% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 18px;
        margin-top: 5px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 160px;
        height: auto;
        margin-top: 0px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -120px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 13px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 50vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 35px 15px 15px 15px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 18px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 4px;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 82% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 180px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 50vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 35vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 80% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 50vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 65% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 57vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 65% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 210px;
        height: auto;
        margin-top: 5px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -130px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 58vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 70% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 200px;
        height: auto;
        margin-top: 5px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 54.5vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 79% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 30px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 200px;
        height: auto;
        margin-top: 5px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 54vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 12px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 290px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 87% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 54vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 45px 15px 15px 15px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 30px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 12px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 32px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        margin-top: 20px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 16px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 45vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 250px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 90% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 190px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 50vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 28px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 32px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        margin-top: 20px;
    }


    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 15px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 16px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 42vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 230px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 95% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 85% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 50vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 70%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 28px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height:1010px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 75% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 180px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 50px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 65% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 180px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 50px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 30px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 57vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        justify-content: flex-start;
        gap: 15px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 65% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 180px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 50px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 57vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 65% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 180px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -90px;
        background-size: contain !important;
        margin-top: 50px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 58vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 24px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 75% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 58% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 50px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 20px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 57vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 75% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 40px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 58% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 50px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 10px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 58vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 80% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 68% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 10px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 55vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 260px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 85% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 68% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 10px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 10px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 15px;
        margin-top: 1px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 50vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 6px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 15px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 8px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        margin-top: 18px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 220px;
        padding: 0px 15px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 95% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 260px !important;
        height: 83% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -100px;
        background-size: contain !important;
        margin-top: 10px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 54vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 4px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 15px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:761px) and (max-width:829px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 420px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 275px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 900px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:740px) and (max-width:760px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 420px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 275px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 900px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:731px) and (max-width:739px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 420px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 275px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 900px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:700px) and (max-width:730px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 430px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 275px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 930px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:676px) and (max-width:699px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 430px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 390px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 250px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 895px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:598px) and (max-width:675px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 475px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 390px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 93% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 250px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 930px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:563px) and (max-width:597px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 535px;
    }

    .reservation_page .text_part {
        height: 535px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 350px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 230px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 20px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 915px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:550px) and (max-width:562px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 529px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 330px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 15px;
        padding-top: 15px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px !important;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 35px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 230px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 20px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 900px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:537px) and (max-width:549px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 525px;
    }

    .reservation_page .text_part {
        height: 475px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        margin-top: 76px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 90% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 220px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 835px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:518px) and (max-width:536px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 22px 16px 22px;
        height: 530px;
    }

    .reservation_page .text_part {
        height: 487px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        margin-top: 76px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 22px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 200px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 832px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:504px) and (max-width:517px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 22px 16px 22px;
        height: 530px;
    }

    .reservation_page .text_part {
        height: 487px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        margin-top: 76px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 22px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 195px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 0px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 835px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:490px) and (max-width:503px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 550px;
    }

    .reservation_page .text_part {
        height: 512px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        margin-top: 76px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 190px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 5px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 835px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:483px) and (max-width:489px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 565px;
    }

    .reservation_page .text_part {
        height: 535px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        margin-top: 76px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 0px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 190px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 5px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 837px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:466px) and (max-width:482px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 593px;
    }

    .reservation_page .text_part {
        height: 593px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 280px;
        width: 100vw;
        margin-top: 74px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        /* padding-top: 5px; */
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 180px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 5px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 820px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:453px) and (max-width:465px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 591px;
    }

    .reservation_page .text_part {
        height: 591px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 280px;
        width: 100vw;
        margin-top: 76px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 130px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 11px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 10px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 180px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 5px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 820px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:420px) and (max-width:452px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 617px;
    }

    .reservation_page .text_part {
        height: 617px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 280px;
        width: 100vw;
        padding-top: 18px;
        margin-top: 58px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 100px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 11px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 95% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 160px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 20px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 805px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
        margin-right: 10px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
        margin-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:411px) and (max-width:419px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 617px;
    }

    .reservation_page .text_part {
        height: 617px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 265px;
        width: 100vw;
        padding-top: 18px;
        margin-top: 58px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 100px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 11px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 100% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 145px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 790px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
        margin-right: 10px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
        margin-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:400px) and (max-width:410px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 653px;
    }

    .reservation_page .text_part {
        height: 653px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 265px;
        width: 100vw;
        padding-top: 18px;
        margin-top: 58px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 100px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 11px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 100% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 145px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 785px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
        margin-right: 10px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
        margin-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:393px) and (max-width:399px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 626px;
    }

    .reservation_page .text_part {
        height: 626px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 240px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 100px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 100% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 145px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 780px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 14%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:390px) and (max-width:392px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 656px;
    }

    .reservation_page .text_part {
        height: 656px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 260px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 100px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 100% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 145px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 800px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 14%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:375px) and (max-width:389px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 673px;
    }

    .reservation_page .text_part {
        height: 673px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 240px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 100px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 100% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 145px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 825px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 14%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:362px) and (max-width:374px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 678px;
    }

    .reservation_page .text_part {
        height: 678px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 230px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 80px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        padding-right: 5px;
        padding-top: 5px;
        padding-left: 1px;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 11px 11px 15px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        background-image: url(../../assests/launcher_page_img/main\ graphic\ 1.png);
        height: 100%;
        width: 100%;
        max-height: 235px;
        max-width: 355px;
        object-fit: contain;
        background-repeat: round;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 100% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 135px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 860px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:348px) and (max-width:361px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 682px;
    }

    .reservation_page .text_part {
        height: 682px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 230px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 80px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 3px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 13px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 99% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 135px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 860px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:328px) and (max-width:347px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 682px;
    }

    .reservation_page .text_part {
        height: 682px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 230px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 60px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 12px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 99% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 125px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 860px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:326px) and (max-width:327px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 15px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 682px;
    }

    .reservation_page .text_part {
        height: 720px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 230px;
        width: 100vw;
        margin-top: 63px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 60px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px 16px 16px 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 98% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 125px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 850px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:318px) and (max-width:325px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 15px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 682px;
    }

    .reservation_page .text_part {
        height: 720px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 210px;
        width: 100vw;
        margin-top: 65px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 60px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 98% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 125px !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 830px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (max-width:317px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 15px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 704px;
    }

    .reservation_page .text_part {
        height: 704px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 205px;
        width: 100vw;
        margin-top: 68px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 23px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 16px 60px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .card-img-text-box .card-img-area {
        width: 100%;
        height: 100%;
        padding: 16px;
    }

    .card-img-text-box .card-img-area img {
        width: 100% !important;
        height: 100% !important;
        border: 1px solid #000;
        border-radius: 8px;
        object-fit: cover !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 98% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 0px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 38% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: -0px;
        margin-right: 10px;
        background-size: contain !important;
        margin-top: 10px !important;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.3px #2E2E2E;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 865px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.5px #2E2E2E;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}