

.paragraph {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
}

.heading {
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    margin: 40px 0 20px 0px;
    text-align: center;
}

.orderHeading {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 21px;
}

.orderHeading h5 {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    padding-left: 20px;
    margin: 15px 0px;
}


.orderList2 {
    list-style: none;
    counter-reset: list;
}

.orderList2>li {
    counter-increment: list;
    margin-top: 10px;
}

.orderList2>li::before {
    content: "(" counter(list, lower-alpha) ") ";
    font-weight: 600;
    margin-left: -12px;

}

.paragraph1 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-left: 45px;
    margin-top: -29px;
    line-height: 34px;
}

.paragraph2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-left: 20px;
    line-height: 33px;
}

.orderHeading2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.orderList3 {
    padding-left: 20px;
}

.orderHeading3 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
}

.custom-counter {
    list-style: none;
    padding-left: 0px;
    margin-left: -48px;
}

.paragraph3 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 400;
    padding-left: 88px;
    line-height: 30px;
}