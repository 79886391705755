.confirmation-page-heading {
    font-size: 32px;
    padding-top: 25px;
    margin-top: 33px;
    font-weight: 300;
    line-height: 48px;
    font-family: "Inter", sans-serif;
}

.thanks-paragraph {
    width: 75%;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
}

.social-icons {
    width: 75%;
    margin-top: 24px;
    padding-top: 19px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 12px;
}

.image-display {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-display img {
    width: 550px;
    height: 687px;
    margin-left: 19px;
    margin-right: 28px;
}

.back-button {
    margin-top: 37px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    padding: 16px 182px;
    background-color: #2E2E2E;
    color: white;
    border-radius: 2px;
    margin-left: 82px;
}

@media screen and (min-width:1430px) and (min-height:1010px) {
    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 950px) and (max-height: 1009px) {
    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 880px) and (max-height: 949px) {
    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 800px) and (max-height: 879px) {

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 700px) and (max-height: 749px) {
    .image-display img {
        width: 500px;
        height: 630px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 630px) and (max-height: 699px) {
    .image-display img {
        width: 500px;
        height: 600px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 19px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 12px;
    }

    .back-button {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 560px) and (max-height: 629px) {
    .image-display img {
        width: 450px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 15px;
        margin-top: 15px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width: 1430px) and (max-height: 559px) {
    .image-display img {
        width: 380px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 12px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 182px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 82px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height:1010px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;
    }

    .image-display img {
        width: 530px;
        height: 650px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 12px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 950px) and (max-height: 1009px) {
    .image-display img {
        width: 530px;
        height: 650px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 880px) and (max-height: 949px) {
    .image-display img {
        width: 530px;
        height: 650px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 800px) and (max-height: 879px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .image-display img {
        width: 500px;
        height: 620px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 750px) and (max-height: 799px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .image-display img {
        width: 500px;
        height: 620px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 700px) and (max-height: 749px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .image-display img {
        width: 500px;
        height: 620px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 630px) and (max-height: 699px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .image-display img {
        width: 500px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 8px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 175px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 550px) and (max-height: 629px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .image-display img {
        width: 450px;
        height: 500px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 32px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (max-height: 549px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .image-display img {
        width: 420px;
        height: 450px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 8px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 32px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 18px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 72px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height:1010px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 950px) and (max-height: 1009px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 880px) and (max-height: 949px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 800px) and (max-height: 879px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 700px) and (max-height: 799px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 630px) and (max-height: 699px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 550px) and (max-height: 629px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .image-display img {
        width: 520px;
        height: 550px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 32px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (max-height: 549px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 64px !important;
    }

    .image-display img {
        width: 430px;
        height: 450px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 32px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 8px;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 160px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 64px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height:1010px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .image-display img {
        width: 400px;
        height: 480px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 950px) and (max-height: 1009px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .image-display img {
        width: 400px;
        height: 480px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 880px) and (max-height: 949px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .image-display img {
        width: 400px;
        height: 480px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 800px) and (max-height: 879px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .image-display img {
        width: 400px;
        height: 480px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 700px) and (max-height: 799px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .image-display img {
        width: 380px;
        height: 450px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 630px) and (max-height: 699px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .image-display img {
        width: 380px;
        height: 450px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 4px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 8px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 550px) and (max-height: 629px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .image-display img {
        width: 380px;
        height: 450px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 8px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 32px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
    }

    .social-icons {
        width: 75%;
        margin-top: 8px;
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 4px;
    }

    .back-button {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (max-height: 549px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 28px 0px 28px 55px !important;
    }

    .image-display img {
        width: 350px;
        height: 400px;
    }

    .confirmation-page-heading {
        font-size: 30px;
        padding-top: 8px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 32px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-top: 10px;
    }

    .social-icons {
        width: 75%;
        margin-top: 8px;
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 4px;
    }

    .back-button {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 150px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 55px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height:1010px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 950px) and (max-height: 1009px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 880px) and (max-height: 949px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 800px) and (max-height: 879px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 750px) and (max-height: 799px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 700px) and (max-height: 749px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 400px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 630px) and (max-height: 699px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 430px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 44px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 550px) and (max-height: 629px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 450px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 24px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 4px;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:900px) and (max-width:999px) and (max-height: 549px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .image-display img {
        width: 380px;
        height: 380px;
    }

    .confirmation-page-heading {
        font-size: 24px;
        padding-top: 12px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 28px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin-top: 10px;
    }

    .social-icons {
        width: 75%;
        margin-top: 8px;
        padding-top: 4px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 4px;
    }

    .back-button {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height:1010px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 950px) and (max-height: 1009px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 880px) and (max-height: 949px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 32px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 800px) and (max-height: 879px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 26px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 750px) and (max-height: 799px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 24px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 16px;
        padding-top: 16px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 700px) and (max-height: 749px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 24px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 630px) and (max-height: 699px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 24px;
        padding-top: 16px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .back-button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 550px) and (max-height: 629px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 430px;
    }

    .confirmation-page-heading {
        font-size: 24px;
        padding-top: 8px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 24px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 8px;
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 4px;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:830px) and (max-width:899px) and (max-height: 549px) {
    .confirmation-page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .image-display img {
        width: 340px;
        height: 350px;
    }

    .confirmation-page-heading {
        font-size: 24px;
        padding-top: 8px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 24px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 8px;
        padding-top: 0px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 4px;
    }

    .back-button {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 110px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 40px;
    }
}

@media screen and (min-width:700px) and (max-width:829px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #DEDCD3;
        margin-top: 190px;
    }

    .image-display img {
        margin-top: 10px;
        width: 60%;
        height: auto;
    }

    .thanks-paragraph {
        width: 90% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 10px 35px 0px 35px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 155px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 35px;
        margin-bottom: 50px;
    }
}

@media screen and (min-width:600px) and (max-width:699px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #DEDCD3;
        margin-top: 190px;
    }

    .image-display img {
        margin-top: 10px;
        width: 350px;
        height: 420px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 10px 35px 0px 35px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 155px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 35px;
        margin-bottom: 50px;
    }
}

@media screen and (min-width:550px) and (max-width:599px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #DEDCD3;
        margin-top: 190px;
    }

    .image-display img {
        margin-top: 10px;
        width: 350px;
        height: 420px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 10px 35px 0px 35px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 35px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width:513px) and (max-width:549px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #DEDCD3;
        margin-top: 190px;
    }

    .image-display img {
        margin-top: 10px;
        width: 350px;
        height: 420px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width:495px) and (max-width:512px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: flex !important;
        justify-content: center;
        align-items: center;
        background: #DEDCD3;
        margin-top: 190px;
    }

    .image-display img {
        margin-top: 10px;
        width: 350px;
        height: 420px;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 120px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width:479px) and (max-width:494px) {

    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 32px;
        padding-right: 40px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: none !important;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        margin-top: 150px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        width: 92% !important;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 90px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 30px !important;
    }
}

@media screen and (min-width:440px) and (max-width:478px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: none !important;
    }

    .confirmation-page-heading {
        font-size: 28px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 20px;
        padding-top: 15px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        margin-top: 150px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        width: 92% !important;
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 90px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 30px !important;
    }
}

@media screen and (min-width:401px) and (max-width:439px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: none !important;
    }

    .confirmation-page-heading {
        width: 80% !important;
        font-size: 32px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 12px !important;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        margin-top: 137px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        width: 92% !important;
        margin-top: 24px !important;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 90px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 30px !important;
    }
}

@media screen and (min-width:390px) and (max-width:400px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: none !important;
    }

    .confirmation-page-heading {
        width: 80% !important;
        font-size: 32px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 16px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 8px !important;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        margin-top: 137px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        width: 90%;
        margin-top: 16px !important;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 90px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 30px !important;
    }
}

@media screen and (min-width:350px) and (max-width:389px) {

    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 45px;
        padding-right: 45px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: none !important;
    }

    .confirmation-page-heading {
        width: 80% !important;
        font-size: 32px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 12px !important;
    }

    .text-container {
        padding: 20px 16px 0px 16px !important;
        margin-top: 137px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        width: 90%;
        margin-top: 16px !important;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 80px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px !important;
        margin-right: 16px !important;
        margin-bottom: 30px !important;
    }
}

@media screen and (max-width:349px) {

    .confirmation-page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px;
    }

    .confirmation-main-container {
        height: 100vh !important;
        background: #ECEBE4;
    }

    .image-display {
        display: none !important;
    }

    .confirmation-page-heading {
        width: 90% !important;
        font-size: 32px;
        padding-top: 0px;
        margin-top: 0px;
        font-weight: 300;
        line-height: 48px;
        font-family: "Inter", sans-serif;
    }

    .thanks-paragraph {
        width: 100% !important;
        font-family: "Inter", sans-serif;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        margin-top: 12px;
    }

    .social-icons {
        width: 75%;
        margin-top: 12px;
        padding-top: 12px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 16px !important;
    }

    .text-container {
        padding: 22px 16px 0px 16px !important;
        margin-top: 150px !important;
        height: auto !important;
    }

    .confirmation-page .confirmation-text-container {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
    }

    .back-button {
        margin-top: 8px !important;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 40px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        margin-left: 16px;
        margin-bottom: 30px !important;
    }
}