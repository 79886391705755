* {
    margin: 0px;
    padding: 0px;
    font-family: "Inter", sans-serif !important;
}

.redeem-1-month-container {
    width: 100%;
    height: 100%;
    background-color: rgba(223, 221, 211, 1);
    border-width: 1px 0px 1px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 1);
    padding: 36px 16px;
}

.redeem-trial {
    width: 100%;
    height: 300px;
    background-color: rgba(28, 28, 30, 1);
    border-radius: 8px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.redeem-minimis-logo {
    padding: 32px 89.31px 16px 89.31px;
}

.redeem-text {
    padding: 0px 0px 16px 0px;
}

.redeem-text p {
    color: rgba(236, 235, 229, 1);
    font-size: 20px;
    font-weight: 200;
    line-height: 22px;
}

.redeem-line {
    opacity: 20%;
    width: 289px;
    height: 0px;
    border-bottom: 1px solid rgba(236, 235, 229, 1);
    padding: 0px 30px;
}

.redeem-trial-text {
    padding-top: 16px;
    padding-bottom: 20px;
}

.redeem-trial-text p {
    color: rgba(236, 235, 229, 1);
    opacity: 50%;
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
}

.redeem-code-container {
    display: flex;
    margin-top: 36px;
}

.redeem-code-text {
    width: 55%;
    height: auto;
    padding: 28px 28px 28px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.redeem-code-heading {
    width: 100%;
    height: auto;
    padding-bottom: 12px;
}

.redeem-code-heading p {
    font-size: 24px;
    line-height: 40px;
    font-weight: 300;
    color: rgba(46, 46, 46, 1);
}

.redeem-code-content {
    width: 100%;
    height: auto;
    padding-bottom: 12px;
}

.redeem-code-content p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    color: rgba(46, 46, 46, 1);
}

.redeem-code-input-container {
    display: flex;
    margin-top: 12px;
    gap: 7px;
}

.redeem-code-input input {
    width: 264px;
    height: 46px;
    background-color: rgba(236, 235, 229, 1);
    border-radius: 2px;
    outline: none;
    padding-left: 12px;
    border: 0px;
}

.redeem-code-input-container button {
    width: auto;
    background-color: rgba(46, 46, 46, 1);
    outline: none;
    border-radius: 2px;
    border: 0px;
    color: rgba(236, 235, 229, 1);
    padding: 12px 12px;
    line-height: 19px;
}

.redeem-code-image {
    width: 45%;
    height: auto;
    display: flex;
    justify-content: center;
}

.redeem-code-image img {
    width: 80%;
}

.how-work-conatiner {
    width: 100%;
    height: auto;
    background-color: rgba(244, 244, 239, 1);
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 1);
    padding: 24px 16px 24px 66px;
}

.how-work {
    width: 100%;
    height: auto;
    padding-bottom: 8px !important;
}

.how-work p {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #2E2E2E;
}

.how-work-method {
    width: 100%;
    height: auto;
    margin-top: 16px !important;
}

.how-work-heading {
    width: 100%;
    height: auto;
    display: flex;
    gap: 8px;
}

.how-work-icon {
    width: 28px;
    height: auto;
}

.how-work-icon img {
    width: 100%;
}

.how-work-heading-content {
    width: 100%;
    height: auto;
}

.how-work-heading-content p {
    color: #2E2E2E;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
}

.how-work-text-content {
    width: 90%;
    height: auto;
    margin-left: 14px;
    border-left: 1px solid #2E2E2E;
    margin-top: 16px;
}

.how-work-text {
    width: 100%;
    min-height: 24px;
    height: auto;
    padding-left: 21px;
}

.how-work-text p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}

@media screen and (min-width: 830px) and (max-width: 1100px) {

    .redeem-code-image img {
        width: 95%;
    }
}

@media screen and (min-width: 600px) and (max-width: 829px) {
    .redeem-code-container {
        display: flex;
        flex-direction: column;
        margin-top: 36px;
    }

    .redeem-code-text {
        width: 100%;
        height: auto;
        padding: 28px 28px 28px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .redeem-code-image {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .redeem-code-image img {
        width: 85%;
    }
}

@media screen and (min-width: 300px) and (max-width: 599px) {

    .redeem-trial {
        width: 100%;
        height: auto !important;
        background-color: rgba(28, 28, 30, 1);
        border-radius: 8px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .redeem-text p {
        color: rgba(236, 235, 229, 1);
        font-size: 16px;
        font-weight: 200;
        line-height: 22px;
    }

    .redeem-trial-text p {
        color: rgba(236, 235, 229, 1);
        opacity: 50%;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
    }

    .redeem-code-text {
        width: 100%;
        height: auto;
        padding: 28px 0px 28px 0px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .redeem-code-heading p {
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;
        color: rgba(46, 46, 46, 1);
    }

    .redeem-code-content p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: rgba(46, 46, 46, 1);
    }

    .redeem-code-input {
        width: 80%;
    }

    .redeem-code-input-container {
        display: flex;
        margin-top: 0px !important;
        gap: 7px;
    }

    .redeem-code-input input {
        width: 100%;
        height: 46px;
        background-color: rgba(236, 235, 229, 1);
        border-radius: 2px;
        outline: none;
        padding-left: 12px;
        border: 0px;
    }

    .redeem-code-input-container button {
        width: 20%;
        min-width: 90px !important;
    }

    .redeem-code-container {
        display: flex;
        flex-direction: column;
        margin-top: 20px !important;
    }

    .redeem-code-text {
        width: 100%;
        height: auto;
        padding: 0px 0px 28px 0px !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .redeem-code-image {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .redeem-code-image img {
        width: 100%;
    }

    .how-work-conatiner {
        width: 100%;
        height: auto;
        background-color: rgba(244, 244, 239, 1);
        border-width: 0px 0px 1px 0px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 1);
        padding: 24px 16px !important;
    }

    .how-work p {
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        color: #2E2E2E;
    }
}