.scale-indicator-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 32px;
}

.dotfil1 {
    width: 7.22px;
    height: 7.22px;
    background-color: #414750;
    border-radius: 50%;
    transition: background-color 0.3s, border 0.3s;
}

.dotfil2 {
    width: 7.22px;
    height: 7.22px;
    background-color: #414750;
    border-radius: 50%;
    transition: background-color 0.3s, border 0.3s;
}

.dotfil3 {
    width: 7.22px;
    height: 7.22px;
    background-color: #A9977D;
    border-radius: 50%;
    transition: background-color 0.3s, border 0.3s;
}

.dot1 {
    border: 1px solid #A9977D;
    padding: 6px;
    border-radius: 50%;
}

.dot2.active {
    border: 6px solid #A9977D;
    padding: 6px;
    border-radius: 50%;
}

.dot3 {
    border: 1px solid #A9977D;
    padding: 6px;
    border-radius: 50%;
}

.line1 {
    width: 122px;
    height: 2px;
    background-color: #414750;
}

.line2 {
    width: 120px;
    height: 1px;
    background-color: #A9977D;
}

.formCheckout {
    padding: 24px;
    background: #DFDDD3;
    border-radius: 4px;
    margin-top: 48px;
}

.checkout-form {
    display: flex;
    flex-direction: column;
}

.label1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.label2 {
    position: absolute;
    top: 36px;
    width: max-content
}

.label2 .chp1 {
    font-size: 14px;
    font-weight: lighter !important;
    font-family: "Inter", sans-serif;
    color: #2E2E2E;
    margin-top: -5px;
    line-height: 15px;
}

.label2 .chp2.active {
    font-size: 14px;
    font-weight: 600 !important;
    font-family: "Inter", sans-serif;
    color: #2E2E2E;
    line-height: 15px;
}

.label2 .chp3 .active {
    font-size: 14px;
    font-weight: lighter !important;
    font-family: "Inter", sans-serif;
    line-height: 15px;
    color: #A9977D;
    margin-top: -5px;
}

.personalDetail {
    padding: 16px 14px;
    background: #FCFCFC;
    border-radius: 4px;
}

.personalDetail .h6 {
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
}

.input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 11px;
    width: 100%;
}

.input-row label {
    font-size: 11px;
    color: #9fa2a6;
    font-family: "Inter", sans-serif;
    line-height: 21px;
}

.input-row .emailField {
    width: 42%;
}

input:focus {
    outline: none;
    /* background: none; */
    text-decoration: none;
}

.input-row .phoneField {
    width: 42% !important;
}

.input-row .zipcodeField {
    width: 16%;
}

.input-row .emailField input {
    width: 94%;
    padding: 5.8px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.input-row .phoneField input {
    width: 94% !important;
}

.input-row .zipcodeField input {
    width: 100%;
    padding: 5.8px 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.react-tel-input .flag-dropdown {
    border: 1px solid #ced4da !important;
    border-radius: 32px;
}

.react-tel-input .form-control {
    width: -webkit-fill-available !important;
    height: 38px !important;
    border: 1px solid #ced4da !important;
}

.card-details {
    margin: 20px 0px 0px 0px;
    padding: 16px 8px !important;
    background: #FCFCFC;
    border-radius: 4px;
}

.card-details .h6 {
    font-size: 14px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
}

.card-details .apple-or-card-payment {
    width: 100% !important;
    height: 19px !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}

.card-details .apple-or-card-payment p {
    font-size: 16px;
    font-weight: 300;
    line-height: 18px;
    color: #2e2e2e;
}

.card-details .card-payment {
    width: 100%;
    padding: 0px 8px !important;
    height: 48px !important;
    max-height: 48px !important;
    border: none;
    margin-top: 12px;
}

.card-details .pay-button {
    width: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    max-height: 48px !important;
    margin: 0px 0px 0px 0px !important;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    border: none;
    outline: none;
    padding: 12px 0px;
    background-color: #2E2E2E;
    color: white;
    border-radius: 2px;
    font-size: 17px;
    cursor: pointer;
}

.card-details .apple-payment {
    width: 100%;
    padding: 8px;
    height: 64px !important;
    max-height: 64px !important;
    border: 0.78px solid #41475033;
    margin-top: 28px;
    margin-bottom: 12px;
}

.card-details .apple-pay-button {
    width: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    max-height: 48px !important;
    margin: 0px 0px 0px 0px;
    border: none;
    outline: none;
    padding: 12px 0px;
    background-color: #000000;
    color: white;
    border-radius: 6px;
    font-size: 17px;
    cursor: pointer;
}

.card-details .apple-pay-text {
    height: 27px !important;
    max-height: 27px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

.card-details .apple-pay-text p {
    font-family: sans-serif !important;
    font-weight: 500 !important;
    font-size: 19px !important;
    line-height: 22px !important;
    color: #fff;
}

.card-details .apple-payment-text {
    max-height: 27px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}

.card-details .apple-payment-text p {
    font-family: sans-serif !important;
    font-weight: 500 !important;
    font-size: 23px !important;
    line-height: 27px !important;
    color: #fff;
}

.card-details label {
    font-size: 11px;
    color: #9fa2a6;
    font-family: "Inter", sans-serif;
    line-height: 21px;
}

.card-details .card_details_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

h6 {
    margin-bottom: 0px !important;
}

.card-details .card_details_header div img {
    width: 29.17px;
    height: 20px;
    margin: 0px 4px;
}

.card-details p {
    font-size: 10.5px;
    font-weight: lighter !important;
    font-family: "Inter", sans-serif;
    color: #2E2E2E;
    line-height: 18.7px;
}

.icons_text_class {
    margin-top: 15px;
}

.lock_icon_class {
    height: 18px;
    width: 18px;
    border-radius: 50px;
    display: flex;
    background: #F2F4F7;
    align-items: center;
    justify-content: center;
    margin-right: 11px;
}

.lock_icon_class svg {
    height: 9px;
    width: 9px;
}

.card-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ECEBE4;
    padding: 20px 12.5px 12.5px 12.5px;
    margin: 24px 0px 5px 0px;
    border-radius: 8px;
}

.card-details-field {
    width: 35%;
}

.card-details-flex {
    width: 35%;
    display: flex;
}

.card-details-field.small {
    width: 50%;
    margin-left: 10px;
}

.card-details-input,
.card-details-element-container {
    width: 100%;
    padding: 9px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.card-number-container {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 8px;
}

.card-brand-icon {
    width: 25px;
    height: 16px;
    margin-right: 10px;
}

.card-holder-name {
    margin-right: 10px;
    width: 95%;
    padding: 9px;
    height: 34px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.card-element {
    flex: 1;
}

.bottom_div .p1 {
    font-size: 16px;
    font-weight: lighter !important;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    color: #2E2E2E;
    -webkit-text-stroke: 0.2px #2E2E2E;
    opacity: 0.6 !important;
    margin-top: 24px;
}

.bottom_div .p2 {
    font-size: 16px;
    font-weight: lighter !important;
    font-family: "Inter", sans-serif;
    line-height: 24px;
    color: #2E2E2E;
    -webkit-text-stroke: 0.2px #2E2E2E;
    margin-top: 16px;
}

@media screen and (min-width: 1431px) and (min-height:950px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;

    }
}

@media screen and (min-width: 1431px) and (min-height:880px) and (max-height:949px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 740px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1431px) and (min-height:850px) and (max-height:879px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 700px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1431px) and (min-height:750px) and (max-height:849px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 630px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 82px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1431px) and (min-height:700px) and (max-height:749px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 580px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 82px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1431px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 510px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 82px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 9px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1431px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 480px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 82px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1431px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 83px 0px 82px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 435px;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 82px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1431px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 83px 0px 82px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 370px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 82px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:950px) {
    .checkout-container {
        margin: 10px 72px 0px 72px;
        border-radius: 5px;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:880px) and (max-height:949px) {
    .checkout-container {
        margin: 10px 72px 0px 72px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 720px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:750px) and (max-height:879px) {
    .checkout-container {
        margin: 10px 72px 0px 72px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 610px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:700px) and (max-height:749px) {
    .checkout-container {
        margin: 10px 72px 0px 72px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 575px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 5px 72px 0px 72px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 510px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 72px 0px 72px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 475px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        color: #2E2E2E;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 72px 0px 72px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 430px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1300px) and (max-width: 1430px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 72px 0px 72px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 370px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 25px 0px 25px 72px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:950px) {
    .checkout-container {
        margin: 10px 64px 0px 64px;
        border-radius: 5px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:880px) and (max-height:949px) {
    .checkout-container {
        margin: 10px 64px 0px 64px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 730px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 64px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:750px) and (max-height:879px) {
    .checkout-container {
        margin: 0px 64px 0px 64px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 630px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:700px) and (max-height:749px) {
    .checkout-container {
        margin: 0px 64px 0px 64px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 580px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details .pay-button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 0px 64px 0px 64px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 510px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 64px 0px 64px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 480px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 64px 0px 64px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 430px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 40px 0px 40px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 400px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 64px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:950px) {
    .checkout-container {
        margin: 10px 55px 0px 55px !important;
        border-radius: 5px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:880px) and (max-height:949px) {
    .checkout-container {
        margin: 10px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 730px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:750px) and (max-height:879px) {
    .checkout-container {
        margin: 5px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 615px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:700px) and (max-height:749px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 580px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 520px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 485px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 430px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 55px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 390px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 55px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:1000px) {
    .checkout-container {
        margin: 10px 55px 0px 55px !important;
        border-radius: 5px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:950px) and (max-height:999px) {
    .checkout-container {
        margin: 10px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 810px !important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:880px) and (max-height:949px) {
    .checkout-container {
        margin: 10px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 730px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:770px) and (max-height:879px) {
    .checkout-container {
        margin: 5px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 640px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:700px) and (max-height:769px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 585px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 510px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 485px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 435px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 55px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1099px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 55px 0px 55px !important;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 410px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 55px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:1000px) {
    .checkout-container {
        margin: 10px 40px 0px 40px;
        border-radius: 5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:950px) and (max-height:999px) {
    .checkout-container {
        margin: 10px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 800px !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:880px) and (max-height:949px) {
    .checkout-container {
        margin: 10px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 730px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:785px) and (max-height:879px) {
    .checkout-container {
        margin: 5px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 585px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:700px) and (max-height:784px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 585px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 510px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 480px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 8px;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 5px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 999px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 435px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 8px;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 5px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 900px) and (max-width: 999px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 385px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 8px;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 5px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:1100px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
    }
}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:1000px) and (max-height:1099px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 880px !important;
    }
}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:900px) and (max-height:999px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 760px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:810px) and (max-height:899px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 680px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:700px) and (max-height:809px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 585px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 35px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 19px;
    }

    .bottom_div .p2 {
        font-size: 16px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 24px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 10px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:630px) and (max-height:699px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 515px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .formCheckout {
        padding: 20px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 40px;
    }

    .personalDetail {
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 15px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 8px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 12px;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 7px;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 14px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:590px) and (max-height:629px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        overflow-y: auto !important;
        max-height: 480px !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 8px;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 5px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 830px) and (max-width: 899px) and (min-height:540px) and (max-height:589px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        max-height: 445px !important;
        overflow-y: auto !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        opacity: 0.6 !important;
        margin-top: 8px;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 5px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width: 830px) and (max-width: 899px) and (max-height:539px) {
    .checkout-container {
        margin: 0px 40px 0px 40px;
        border-radius: 5px;
        max-height: 380px !important;
        overflow-y: auto !important;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .formCheckout {
        padding: 14px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
    }

    .personalDetail {
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 5px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 10px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 8px;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 5px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .icons_text_class {
        margin-top: 8px;
    }

    .card-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 8px 12.5px 12.5px 7.5px;
        margin: 8px 0px 5px 0px;
        border-radius: 8px;
    }

    .label2 .chp1 {
        font-size: 11px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 13px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width:679px) and (max-width:829px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -35px;
        margin-right: -35px;
        position: absolute;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1127px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }


    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 188px;
        padding-bottom: 20px;
    }

    .checkout-container {
        margin: 0px 35px 0px 35px;
        border-radius: 5px;
    }

    .line1 {
        width: 40%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 40%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px !important;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1245px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 35px 48px 35px;
        margin-left: -35px !important;
        margin-right: -35px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }
}

@media screen and (min-width:643px) and (max-width:678px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -35px;
        margin-right: -35px;
        position: absolute;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }


    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1130px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 410px;
        width: 100vw;
        margin-top: 188px;
        padding-bottom: 20px;
    }

    .checkout-container {
        margin: 0px 35px 0px 35px;
        border-radius: 5px;
    }

    .line1 {
        width: 40%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 40%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1248px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 35px 58px 35px;
        margin-right: -35px !important;
        margin-left: -35px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:631px) and (max-width:642px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -35px;
        margin-right: -35px;
        position: absolute;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }


    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1131px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 410px;
        width: 100vw;
        margin-top: 188px;
        padding-bottom: 20px;
    }

    .checkout-container {
        margin: 0px 35px 0px 35px;
        border-radius: 5px;
    }

    .line1 {
        width: 40%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 40%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1248px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 35px 48px 35px;
        margin-right: -35px !important;
        margin-left: -35px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:613px) and (max-width:630px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -35px;
        margin-right: -35px;
        position: absolute;
        top: 70px;
        padding-left: 35px;
        padding-right: 35px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }


    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1156px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 410px;
        width: 100vw;
        margin-top: 188px;
        padding-bottom: 20px;
    }

    .checkout-container {
        margin: 0px 35px 0px 35px;
        border-radius: 5px;
    }

    .line1 {
        width: 40%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 40%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 30px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 30px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1273px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 30px 0px 30px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 35px 48px 35px;
        margin-right: -35px !important;
        margin-left: -35px !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:605px) and (max-width:612px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1065px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 380px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 20px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1183px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 16px 48px 19px;
        margin-left: -16px;
        margin-right: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:593px) and (max-width:604px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1075px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 390px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 20px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1166px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 16px 48px 19px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:563px) and (max-width:592px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1099px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 390px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 10px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1217px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 16px 48px 19px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:550px) and (max-width:562px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1063px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 355px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1153px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 16px 48px 19px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

}

@media screen and (min-width:537px) and (max-width:549px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1029px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 320px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1147px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 16px 48px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }


    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:515px) and (max-width:536px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1029px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 320px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 10px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 24px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .card-details p {
        font-size: 12.5px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 23.7px;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1147px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 28px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 1px 16px 48px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }


    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:479px) and (max-width:514px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 16px;
        padding-right: 16px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1050px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 312px;
        width: 100vw;
        margin-top: 170px;
        padding-bottom: 10px;
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 16px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1277px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 18px 16px 48px 16px;
        margin-left: -16px;
        margin-right: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }
}

@media screen and (min-width:467px) and (max-width:478px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 50px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1020px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        padding-top: 20px;
        margin-top: 150px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .personalDetail h6 {
        font-size: 14px !important;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 16px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1246px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 18px 16px 50px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}


@media screen and (min-width:456px) and (max-width:466px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 50px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1029px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        padding-top: 20px;
        margin-top: 150px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .personalDetail h6 {
        font-size: 14px !important;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 16px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1227px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 18px 16px 50px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:437px) and (max-width:455px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 50px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1029px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        padding-top: 20px;
        margin-top: 150px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .personalDetail h6 {
        font-size: 14px !important;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 16px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1255px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 18px 16px 80px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:415px) and (max-width:436px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 50px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1030px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        padding-top: 20px;
        margin-top: 150px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .personalDetail h6 {
        font-size: 14px !important;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 22px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 16px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 17px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1238px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 18px 16px 80px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}


@media screen and (min-width:390px) and (max-width:414px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 50px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1002px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 270px;
        width: 100vw;
        margin-top: 170px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 12px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .personalDetail h6 {
        font-size: 14px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2e2e2e !important;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 16px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 14px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1227px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 18px 16px 48px 16px;
        margin-left: -16px;
        margin-right: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:362px) and (max-width:389px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 965px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 234px;
        width: 100vw;
        margin-top: 170px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .card-details .card_details_header div img {
        width: 20px;
        height: 14px;
        object-fit: contain !important;
        margin: 0px 2px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 12px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .personalDetail h6 {
        font-size: 14px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2e2e2e !important;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 14px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 14px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 8px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1191px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 16px 16px 50px 16px;
        margin-right: -16px;
        margin-left: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:350px) and (max-width:361px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 982px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 234px;
        width: 100vw;
        margin-top: 170px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }

    .card-details .card_details_header h6 {
        font-size: 14px;
    }

    .card-details .card_details_header div img {
        width: 20px;
        height: 14px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 16px 0px 16px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 12px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .personalDetail h6 {
        font-size: 14px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 12px;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 8px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1209px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 16px 16px 50px 16px;
        margin-left: -16px;
        margin-right: -16px;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:335px) and (max-width:349px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -14px;
        margin-right: -14px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }


    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 985px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 14px 0px 14px;
        gap: 14px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 234px;
        width: 100vw;
        margin-top: 170px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: self-start;
        gap: 8px;
    }

    .card-details .card_details_header h6 {
        font-size: 12px;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 14px 0px 14px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 12px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .personalDetail h6 {
        font-size: 12px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 12;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 8px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1212px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 14px 0px 14px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 15px 14px 40px 14px;
        margin-left: -14px;
        margin-right: -14px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (min-width:320px) and (max-width:334px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -14px;
        margin-right: -14px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }


    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 985px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 14px 0px 14px;
        gap: 14px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 234px;
        width: 100vw;
        margin-top: 170px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: self-start;
        gap: 8px;
    }

    .card-details .card_details_header h6 {
        font-size: 12px;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 14px 0px 14px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 12px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .personalDetail h6 {
        font-size: 12px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 12;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 8px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1211px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 14px 0px 14px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 15px 14px 80px 14px;
        margin-left: -14px;
        margin-right: -14px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}

@media screen and (max-width:319px) {
    .scale-indicator-container {
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-bottom: 20px;
        margin-left: -14px;
        margin-right: -14px;
        position: absolute;
        top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        background: #ecebe4;
        height: 120px;
        width: 100%;
    }

    .p3 {
        display: none !important;
    }

    .label2 .chp1 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        margin-top: -5px;
        line-height: 15px;
    }

    .label2 .chp2.active {
        font-size: 14px;
        font-weight: 600 !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 15px;
    }

    .label2 .chp3 {
        font-size: 12px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 15px;
        color: #A9977D;
        margin-top: -5px;
    }


    .reservation_page .cards_part .img_text_box .reservation_checkout_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 977px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 14px 0px 14px;
        gap: 14px;
    }

    .reservation_page .reservation-checkout {
        background: #dedcd3;
        height: 225px;
        width: 100vw;
        margin-top: 170px;
        /* padding-bottom: 5px; */
    }

    .card-details .card_details_header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: self-start;
        gap: 8px;
    }

    .card-details .card_details_header h6 {
        font-size: 12px;
    }

    .card-details .card_details_header div img {
        width: 23px;
        height: 16px;
        margin: 0px 4px;
    }

    .checkout-container {
        margin: 0px 14px 0px 14px;
        border-radius: 5px;
    }

    .line1 {
        width: 38%;
        height: 2px;
        background-color: #414750;
    }

    .line2 {
        width: 37%;
        height: 1px;
        background-color: #A9977D;
    }

    .formCheckout {
        padding: 8px;
        background: #DFDDD3;
        border-radius: 4px;
        margin-top: 12px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .personalDetail {
        padding: 8px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .personalDetail h6 {
        font-size: 12px;
    }

    .card-details {
        margin: 10px 0px 0px 0px;
        padding: 10px 14px;
        background: #FCFCFC;
        border-radius: 4px;
    }

    .bottom_div .p1 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        opacity: 0.6 !important;
        -webkit-text-stroke: 0.2px #2E2E2E;
        margin-top: 0px !important;
    }

    .bottom_div .p2 {
        font-size: 13px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        line-height: 20px;
        color: #2E2E2E;
        margin-top: 12px;
        -webkit-text-stroke: 0.2px #2E2E2E;
    }

    .input-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5px;
        width: 100%;
    }

    .input-row .emailField {
        width: 100%;
    }

    input:focus {
        outline: none;
        /* background: none; */
        text-decoration: none;
    }

    .input-row .phoneField {
        width: 100% !important;
    }

    .input-row .zipcodeField {
        width: 100%;
    }

    .input-row .emailField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .input-row .phoneField input {
        width: 100% !important;
    }

    .input-row .zipcodeField input {
        width: 100%;
        padding: 5.8px 10px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ECEBE4;
        padding: 12.5px;
        margin: 24px 0px 5px 0px;
        border-radius: 8px;
    }

    .card-details-field {
        width: 100%;
    }

    .card-details-flex {
        width: 100%;
        display: flex;
        gap: 10px;
    }

    .card-details-field.small {
        width: 50%;
        margin-left: 0px;
    }

    .card-holder-name {
        width: 100%;
        padding: 9px;
        height: 34px;
        border: 1px solid #ced4da;
        border-radius: 4px;
    }

    .card-details p {
        font-size: 10px;
        font-weight: lighter !important;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        line-height: 14px;
    }

    .lock_icon_class {
        height: 18px;
        width: 18px;
        border-radius: 50px;
        display: flex;
        background: #F2F4F7;
        align-items: center;
        justify-content: center;
        margin-right: 11px;
    }

    .align-items-center {
        align-items: flex-start !important;
    }

    .card-details button {
        width: 100%;
        margin: 28px 0px 0px 0px;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 8px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;
    }

    .reservation_page .text_part {
        width: 100vw;
        background: #ECEBE4;
        transition: height 0.3s ease-in-out;
    }

    .reservation_page .text_part {
        height: auto;
        width: 100vw;
        background: #ECEBE4;
        padding-bottom: 40px;
    }

    .reservation_page .cards_part .img_text_box.move-up-small .reservation_crad_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 1174px;
        justify-content: space-between;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 14px 0px 14px;
    }

    .bottom_div {
        background: #DFDDD3;
        padding: 16px 14px 45px 14px;
        margin-left: -14px;
        margin-right: -14px;
        margin-bottom: 10px;
    }

    .card-details .pay-button {
        width: 100%;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        max-height: 48px !important;
        margin: 0px 0px 0px 0px !important;
        font-family: "Inter", sans-serif;
        font-weight: 400;
        border: none;
        outline: none;
        padding: 12px 0px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        font-size: 16px;
        cursor: pointer;
    }

}