.fallback-spinner {
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
  }
  .loading {
    position: absolute;
    left: calc(50% - 35px);
    top: 50%;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid transparent;
  }